import React, {useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { Container, Row, CardGroup } from 'react-bootstrap'
import PageBuilder from '../components/contentTypes/pageBuilder'
import CaseStudies from '../components/caseStudies'
import Testimonials from '../components/testimonials'

const Category = ({data, slug}) => { 
  const categoryQuery = useStaticQuery(graphql`
  {
    case_studies: allContentfulCaseStudy(
      sort:{ fields: [date], order: DESC }
    ) {
      edges {
        node {
          entryTitle
          slug
          featuredImage {
            gatsbyImageData(width: 460, height: 260, placeholder: BLURRED, quality: 100)
            title
            description
          }
          date(formatString: "MMM DD, YYYY")
          content {
            raw
          }
          categories
          type
        }
      }
    }
    testimonials: allContentfulTestimonial(
      filter: { entryTitle:{ ne: "" }}
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          quote {
            quote
          }
          name
          location
          occupation
          order
        }
      }
    }
  }
`)

  const postData = [];
  const filters = [];
  const filterTypes = [
    {label: "Articles", value: false},
    {label: "Case Studies", value: true}
  ];
  let template = '';


    /**
   * Preprocess post.
   * Add post filters to array.
   */
  const [selected, setSelected] = useState("all")
  const [selectedType, setSelectedType] = useState("all")
  const [posts, setPosts] = useState(postData)

  const typeHandler = val => {
    setSelectedType(val)
    filterPosts(val)
  }

  const categoryHandler = val => {
    setSelected(val)
    filterPosts(val)
  }

  useEffect(() => {
    filterPosts()
  }, [selected, selectedType])
    

  const filterPosts = () => {
    let posts = postData
    setPosts(postData)
    if(selectedType !== "all" || selected !== "all") {
      posts = postData.filter(post => {
        if(selectedType !== "all" && selected === "all") {
          return post.type?.toString() === selectedType?.toString()
        }
        if(selected !== "all" && selectedType === "all")  {
          return post.categories?.includes(selected)
        }
        if(selectedType !== "all" && selected !== "all") {
          return post.type?.toString() === selectedType?.toString() && post.categories?.includes(selected)
        }
      })
      setPosts(posts)
    }
  }

  const querySwitch = (slug) => {
    switch (slug) {
      case 'case-studies':
        template = <CaseStudies data={posts}/>
        return categoryQuery.case_studies.edges;
        break;
      case 'testimonials':
        template = <CardGroup><Testimonials data={postData} pagination={true} /></CardGroup>
        return categoryQuery.testimonials.edges;
        break;
      default:
        return null;
    }
  }

  querySwitch(slug).forEach(element => {
    postData.push(element.node);
    if(element.node.categories !== null && element.node.hasOwnProperty('categories')) {
      element.node.categories.forEach(items => {
        if(filters.indexOf(items) === -1) {
          filters.push(items)
        }
      })
    }
  });

  const renderFilter = () => {
    return (
      <form className="filter-articles">
      <label>Type</label>
        <select
          onChange={e => typeHandler(e.target.value)} 
          value={selectedType}
        >
          <option value="all">All</option>
          { filterTypes.map((types, key) => (
            <option value={types.value} key={key}>{types.label}</option>
          ))}
        </select>

      <label>Category</label>
      <select
        onChange={e => categoryHandler(e.target.value)} 
        value={selected}
      >
        <option value="all">All</option>
        {filters.map((tags, key) => (
          <option value={tags} key={key}>{tags}</option>
        ))}
      </select>
    </form>
    )
  }

  return (
    <Container className="article-list case-studies">
      {slug === 'case-studies' && renderFilter()}
      <Row>
        <PageBuilder data={data} />
        { template }
      </Row>
    </Container>
  )
}


export default Category
